import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "sendreceive-sms"
    }}>{`Send/Receive SMS `}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Protocol`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Endpoint`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Port`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Remark`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SMPP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`smpp.anymessage.cloud`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3300`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SMPP(S)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`smpps.anymessage.cloud`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TLS 1.2`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "allowed-bind-operations"
    }}>{`Allowed Bind Operations`}</h2>
    <p>{`TX, RX, TRX mode are all supported `}</p>
    <h2 {...{
      "id": "validity-period"
    }}>{`Validity Period`}</h2>
    <p>{`Unless otherwise specified the default validity period is 48 hours. Please use relative validitiy period in case of otherwise validity period requests. `}</p>
    <h2 {...{
      "id": "default-datacoding"
    }}>{`Default Datacoding`}</h2>
    <p>{`The systems supports currently the following Datacodingschemes (DCS): `}</p>
    <ul>
      <li parentName="ul">{`DCS = 0 (default) `}</li>
      <li parentName="ul">{`DCS = 1 (GSM) `}</li>
      <li parentName="ul">{`DCS = 3 (Latin-15) `}</li>
      <li parentName="ul">{`DCS = 8 (Unicode) `}</li>
    </ul>
    <h2 {...{
      "id": "dlr-request"
    }}>{`DLR Request`}</h2>
    <p>{`In order to receive delivery receipts for your submitted SMS, you can use the following operations `}</p>
    <ul>
      <li parentName="ul">{`Registered delivery = 0 (NO delivery receipt requested) `}</li>
      <li parentName="ul">{`Registered delivery = 1 (Delivery receipt requested) `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      